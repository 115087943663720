.hero {
  height: 600px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero .announcement {
  color: white;
  background-color: #88aadd;
  position: fixed;
  top: 60px;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero .hero-phrase {
  color: white;
  width: 100%;
  text-shadow: 0 1px 1px #000;
  font-size: 50px;
}

.hero .hero-hashtag {
  color: white;
  width: 100%;
  text-shadow: 0 1px 1px #000;
  font-size: 25px;
  text-align: right;
  padding-right: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1100px) {
  .hero {
    height: 400px;
  }
}

@media only screen and (max-width: 650px) {
  .hero .hero-phrase {
    font-size: 40px;
  }

  .hero .hero-hashtag {
    font-size: 20px;
  }
}
