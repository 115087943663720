.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 5;
}
  .sidebar .close-sidebar {
    text-align: right;
  }
    .sidebar .close-sidebar .close {
      font-size: 30px;
        margin-right: 15px;
        margin-top: 10px;
    }

  .sidebar .navigation {
    display: inline-block;
    padding: 0;
  }
    .sidebar .navigation li {
      display: block;
      font-size: 20px;
      margin-top: 20px;
    }
